export const appRoutes = [
  {
    path: "/",
    name: "HomePage",
    component: () =>
      import(/* webpackChunkName: "Home" */ "@/index/views/HomeView.vue"),
    meta: {
      title: "Welcome!",
    },
  },
  {
    path: "/network-support",
    name: "NetworkSupport",
    component: () =>
      import(/* webpackChunkName: "Home" */ "@/index/views/SupportView.vue"),
    meta: {
      title: "Report an Issue",
    },
  },
  {
    path: "/company/about",
    name: "AboutPage",
    component: () =>
      import(/* webpackChunkName: "Home" */ "@/index/views/AboutView.vue"),
    meta: {
      title: "About Us",
    },
  },
  {
    path: "/company/leadership",
    name: "TeamPage",
    component: () =>
      import(/* webpackChunkName: "Home" */ "@/index/views/TeamPage.vue"),
    meta: {
      title: "Company's Leadership",
    },
  },
  {
    path: "/company/solutions",
    name: "SolutionsView",
    component: () =>
      import(/* webpackChunkName: "Home" */ "@/index/views/SolutionsView.vue"),
    meta: {
      title: "Efashe Solutions",
    },
  },
  {
    path: "/company/contact-us",
    name: "ContactView",
    component: () =>
      import(/* webpackChunkName: "Home" */ "@/index/views/ContactView.vue"),
    meta: {
      title: "Contact Us",
    },
  },
  {
    path: "/company/gallery",
    name: "GalleryView",
    component: () =>
      import(/* webpackChunkName: "Home" */ "@/index/views/GalleryView.vue"),
    meta: {
      title: "Gallery",
    },
  },
  {
    path: "/company/careers",
    name: "VacanciesView",
    component: () =>
      import(/* webpackChunkName: "Home" */ "@/index/views/VacanciesView.vue"),
    meta: {
      title: "Vacancies",
    },
  },
  {
    path: "/company/careers/:slug",
    name: "VacancyDetails",
    component: () =>
      import(/* webpackChunkName: "Home" */ "@/index/views/VacancyDetails.vue"),
    meta: {
      title: "Vacancies",
    },
  },
  {
    path: "/company/careers/:slug/apply",
    name: "VacancyApply",
    component: () =>
      import(/* webpackChunkName: "Home" */ "@/index/views/VacancyApply.vue"),
    meta: {
      title: "Apply",
    },
  },
  {
    path: "/news",
    name: "NewsView",
    component: () =>
      import(/* webpackChunkName: "Home" */ "@/index/views/NewsView.vue"),
    meta: {
      title: "News & Events",
    },
  },
  {
    path: "/news/:slug",
    name: "ArticalDetails",
    component: () =>
      import(/* webpackChunkName: "Home" */ "@/index/views/ArticalDetails.vue"),
    meta: {
      title: "News",
    },
  },
  {
    path: "/support/faq",
    name: "FAQView",
    component: () =>
      import(/* webpackChunkName: "Home" */ "@/index/views/FAQView.vue"),
    meta: {
      title: "Frequently Asked Questions",
    },
  },
];
